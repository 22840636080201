import { Directive, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[rvSwipe]',
  standalone: true
})
export class SwipeDirective {
  #defaultTouch = { x: 0, y: 0, time: 0 }

  @Output()
  swipeleft = new EventEmitter<TouchEvent>()
  @Output()
  swiperight = new EventEmitter<TouchEvent>()

  @Output()
  swipeup = new EventEmitter<TouchEvent>()
  @Output()
  swipedown = new EventEmitter<TouchEvent>()
  constructor() { }

  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchend', ['$event'])
  handleTouch(event: TouchEvent) {
    try {
      const touch = event.touches[0] || event.changedTouches[0]
      if (event.type === 'touchstart') {
        this.#defaultTouch.x = touch.pageX
        this.#defaultTouch.y = touch.pageY
        this.#defaultTouch.time = event.timeStamp
      } else if (event.type === 'touchend') {
        const deltaX = touch.pageX - this.#defaultTouch.x
        const deltaY = touch.pageY - this.#defaultTouch.y
        const deltaTime = event.timeStamp - this.#defaultTouch.time
        if (deltaTime < 500) {
          if (Math.abs(deltaX) > 60) {
            this[deltaX < 0 ? 'swiperight' : 'swipeleft'].emit(event)
          }
          if (Math.abs(deltaY) > 60) {
            this[deltaY < 0 ? 'swipedown' : 'swipeup'].emit(event)
          }
        }
      }
    } catch (e) { }
  }
}
